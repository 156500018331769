import { localStorageService, LOCAL_STORAGE_KEYS } from '../local-storage';
import { destroy, init, setIdentity } from '../helpScout';
import {
  checkIfSessionsIsExpired,
  isAdmin,
  isAdminReceptionistOrRRHH,
  isPatient,
  isProfessional,
  isReceptionist
} from './auth-user.helpers';
import {INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES, USER_INVALID_ERROR, USER_NOT_LOGIN_ERROR} from './auth-user.constants';
import { AuthUser } from './auth-user.model';
import {cookiesService} from "@/services/cookies";
import {COOKIES_KEYS} from "@/services/cookies/cookies.constants";
import environment from "@/environment";

export const authUserService = {
  get: function() {
    return localStorageService.getItem<AuthUser>(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
  },

  set: function(user: AuthUser) {
    if (!user) {
      throw new Error(USER_INVALID_ERROR);
    }

    localStorageService.setItem(LOCAL_STORAGE_KEYS.AUTH_EHEALTH, user);

    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES * 60 * 1000));
    cookiesService.setItem(COOKIES_KEYS.PORTAL_ACCESS, user.access_token, expireDate);
    cookiesService.setItem(COOKIES_KEYS.PORTAL, environment.name);
    cookiesService.setItem(COOKIES_KEYS.PORTAL_URL, environment.apiUrl);
    cookiesService.setItem(COOKIES_KEYS.VIDEO_FRONT_URL, environment.appVideoUrl);
    cookiesService.setItem(COOKIES_KEYS.PORTAL_FRONT_URL, window.location.origin.toString());
    cookiesService.setItem(COOKIES_KEYS.PORTAL_LANGUAGE, window.vm.$i18n.locale);
    cookiesService.setItem(COOKIES_KEYS.TENANT_ID, environment.tenantId);
  },

  remove: function() {
    localStorageService.removeItem(LOCAL_STORAGE_KEYS.AUTH_EHEALTH);
  },

  checkIsSessionIsExpired: function() {
    const userLogged = this.get();
    return userLogged?.lastActivityTime ? checkIfSessionsIsExpired(userLogged.lastActivityTime) : false;
  },

  getUserLocale() {
    const user = this.get();
    return user?.lang || null;
  },

  getUserId() {
    const user = this.get();
    return user?.id || null;
  },

  updateLastActivityTime: function() {
    const userLogged = this.get();
    if (userLogged) {
      userLogged.lastActivityTime = new Date().toISOString();
      this.set(userLogged);
    }
  },

  updateTokens: function(accessToken, refreshToken) {
    const auth = this.get();
    if (auth) {
      auth.access_token = accessToken;
      auth.refresh_token = refreshToken;
      this.set(auth);
    }
  },

  isAdminRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isAdmin(userLogged.rol) && !userLogged.company.id;
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  isCompanyAdminRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isAdmin(userLogged.rol) && userLogged.company.id;
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  isPatientRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isPatient(userLogged.rol);
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  isAdminReceptionistOrRRHHRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isAdminReceptionistOrRRHH(userLogged.rol);
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  isReceptionistRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isReceptionist(userLogged.rol);
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  isProfessionalRol: function() {
    const userLogged = this.get();
    if (userLogged) {
      return isProfessional(userLogged.rol);
    }

    throw new Error(USER_NOT_LOGIN_ERROR);
  },

  // eslint-disable-next-line
  setAuthUserRole: function(data: any, userRole: any) {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
    authUser.fullName = !isPatient ? data.perfil.fullName : authUser.user.name + ' ' + authUser.user.surname;
    authUser.img = !isPatient ? data.perfil.img : '';
    authUser.nombreEmpresa = data?.empresa?.nombre || '';
    authUser.avatarEmpresa = data?.empresa?.avatar || '';
    authUser.logoEmpresa = data?.empresa?.logo || '';
    authUser.servicios_activos = !isPatient ? data.perfil.servicios_activos : [];
    authUser.rol = userRole.rol;
    authUser.user.userRoleId = userRole.id;
    authUser.user.professional_id = !isPatient ? data.usuario.professional_id : null;
    authUser.collegiateNumber = !isPatient ? data.perfil.collegiateNumber : null;
    authUser.trial = !isPatient ? data.perfil.trial : false;
    authUser.start_trial = !isPatient ? data.perfil.start_trial : '';
    authUser.formReserva = !isPatient ? data.perfil.formReserva : false;
    authUser.formPago = !isPatient ? data.perfil.formPago : false;
    authUser.formStepToStep = !isPatient ? data.perfil.stepToStep : false;
    authUser.permisos = !isPatient ? data.perfil.permisos : '';
    authUser.belong_to = !isPatient ? data.perfil.belong_to : 0;
    authUser.color = data?.empresa?.color || '';
    authUser.colorHexadecimal = data?.empresa?.colorHexadecimal || '';
    authUser.permissions = !isPatient ? data.usuario.permissions : null;
    authUser.is_health_personnel = data?.empresa?.is_health_personnel || 1;
    authUser.show_informed_consent = data?.empresa?.informed_consent || 0;
    authUser.is_collective = data?.empresa?.is_collective || 0;
    authUser.have_interconsultation = data?.empresa?.interconsultation === 1;
    authUser.erecipe = !isPatient && typeof data.perfil.erecipe !== 'undefined' ? data.perfil.erecipe : 0;
    authUser.hasSpecialtyToPrescribe =
      !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
        ? data.perfil.hasSpecialtyToPrescribe
        : 0;
    authUser.company = {
      themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
      themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
      permissions: data?.empresa?.permissions || null,
      id: data?.empresa?.id || null,
      sharingReceptionist: data?.empresa?.sharingReceptionist,
    };
    authUser.isSuperAdmin = !isPatient ? data.usuario.isSuperAdmin : false;
    authUser.payment_module = !isPatient ? data.usuario.payment_module : true;
    authUser.isPatient = isPatient;
    authUser.patient = isPatient ? data.patient : false;
    authUser.stripeConfigured =
      !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0;
    authUser.environment = data?.environment || [];

    window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
  },
};

export const showHelpScout = (authUser: AuthUser, appName: string) => {
  if (authUser?.isPatient) {
    return;
  }

  init();

  const { email, id, fullName, company } = authUser;
  const companyId = company?.id || null;
  const professionalId = authUser?.user?.professional_id || null;
  setIdentity(email, id, fullName, companyId, appName, professionalId);

  authUser.isHelpScoutInitialized = true;
  window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
};

export const resetHelpScout = (authUser: AuthUser) => {
  destroy();

  authUser.isHelpScoutInitialized = false;
  window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
};
