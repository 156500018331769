import {getHeader, sickLeaveManagement} from "../../../config/config";
import axios from "axios";

export const TYPES = {
  FINAL: 0,
  TEMP: 2,
};

export const PERSONAL_AFFAIRS = 'personal_affairs';
export const HEALTH_REASONS = 'health_reasons';
export const VACATIONS = 'vacations';

export const RETIREMENT = 'retirement';
export const BAD_PRACTICE = 'bad_practice';
export const DISINTEREST_IN_THE_PROJECT = 'disinterest_in_the_project';

export const OTHER = 'other';

export const REASONS_TEMP = [
  {
    type: PERSONAL_AFFAIRS,
    name: 'sick_leave_management.personal_affairs',
  },
  {
    type: HEALTH_REASONS,
    name: 'sick_leave_management.health_reasons',
  },
  {
    type: VACATIONS,
    name: 'sick_leave_management.vacations',
  },
  {
    type: OTHER,
    name: 'sick_leave_management.other',
  },
];

export const REASONS_FINAL = [
  {
    type: RETIREMENT,
    name: 'sick_leave_management.retirement',
  },
  {
    type: HEALTH_REASONS,
    name: 'sick_leave_management.health_reasons',
  },
  {
    type: BAD_PRACTICE,
    name: 'sick_leave_management.bad_practice',
  },
  {
    type: DISINTEREST_IN_THE_PROJECT,
    name: 'sick_leave_management.disinterest_in_the_project',
  },
  {
    type: OTHER,
    name: 'sick_leave_management.other',
  },
];

export const sendSickLeaveConfig = async (type, professionalId, sickLeaveData) => {
  const url = new URL(sickLeaveManagement(professionalId));

  const params = {
    type,
    reason: sickLeaveData.reason
  };

  if (sickLeaveData.startDate) {
    params["start_at"] = sickLeaveData.startDate;
  }

  if (sickLeaveData.endDate) {
    params["end_at"] = sickLeaveData.endDate;
  }

  const raw = await axios.put(url.toString(), params,{
    headers: getHeader(),
  });
  return raw.data;
};

export const retrieveSickLeaveConfig = async (professionalId) => {
  const url = new URL(sickLeaveManagement(professionalId));

  const raw = await axios.get(url.toString(),{
    headers: getHeader(),
  });

  return {
    type: raw.data.type,
    reason: raw.data.reason,
    start_at: raw.data.start_at,
    end_at: raw.data.end_at
  };
};
